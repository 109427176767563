import _beautifier from "./beautifier";
import _options from "./options";
var exports = {};
var Beautifier = _beautifier.Beautifier,
    Options = _options.Options;

function style_html(html_source, options, js_beautify, css_beautify) {
  var beautifier = new Beautifier(html_source, options, js_beautify, css_beautify);
  return beautifier.beautify();
}

exports = style_html;

exports.defaultOptions = function () {
  return new Options();
};

export default exports;
import _beautifier from "./beautifier";
import _options from "./options";
var exports = {};
var Beautifier = _beautifier.Beautifier,
    Options = _options.Options;

function js_beautify(js_source_text, options) {
  var beautifier = new Beautifier(js_source_text, options);
  return beautifier.beautify();
}

exports = js_beautify;

exports.defaultOptions = function () {
  return new Options();
};

export default exports;
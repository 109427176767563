var exports = {};

function Pattern(input_scanner, parent) {
  this._input = input_scanner;
  this._starting_pattern = null;
  this._match_pattern = null;
  this._until_pattern = null;
  this._until_after = false;

  if (parent) {
    this._starting_pattern = this._input.get_regexp(parent._starting_pattern, true);
    this._match_pattern = this._input.get_regexp(parent._match_pattern, true);
    this._until_pattern = this._input.get_regexp(parent._until_pattern);
    this._until_after = parent._until_after;
  }
}

Pattern.prototype.read = function () {
  var result = this._input.read(this._starting_pattern);

  if (!this._starting_pattern || result) {
    result += this._input.read(this._match_pattern, this._until_pattern, this._until_after);
  }

  return result;
};

Pattern.prototype.read_match = function () {
  return this._input.match(this._match_pattern);
};

Pattern.prototype.until_after = function (pattern) {
  var result = this._create();

  result._until_after = true;
  result._until_pattern = this._input.get_regexp(pattern);

  result._update();

  return result;
};

Pattern.prototype.until = function (pattern) {
  var result = this._create();

  result._until_after = false;
  result._until_pattern = this._input.get_regexp(pattern);

  result._update();

  return result;
};

Pattern.prototype.starting_with = function (pattern) {
  var result = this._create();

  result._starting_pattern = this._input.get_regexp(pattern, true);

  result._update();

  return result;
};

Pattern.prototype.matching = function (pattern) {
  var result = this._create();

  result._match_pattern = this._input.get_regexp(pattern, true);

  result._update();

  return result;
};

Pattern.prototype._create = function () {
  return new Pattern(this._input, this);
};

Pattern.prototype._update = function () {};

exports.Pattern = Pattern;
export default exports;
var exports = {};

function TokenStream(parent_token) {
  // private
  this.__tokens = [];
  this.__tokens_length = this.__tokens.length;
  this.__position = 0;
  this.__parent_token = parent_token;
}

TokenStream.prototype.restart = function () {
  this.__position = 0;
};

TokenStream.prototype.isEmpty = function () {
  return this.__tokens_length === 0;
};

TokenStream.prototype.hasNext = function () {
  return this.__position < this.__tokens_length;
};

TokenStream.prototype.next = function () {
  var val = null;

  if (this.hasNext()) {
    val = this.__tokens[this.__position];
    this.__position += 1;
  }

  return val;
};

TokenStream.prototype.peek = function (index) {
  var val = null;
  index = index || 0;
  index += this.__position;

  if (index >= 0 && index < this.__tokens_length) {
    val = this.__tokens[index];
  }

  return val;
};

TokenStream.prototype.add = function (token) {
  if (this.__parent_token) {
    token.parent = this.__parent_token;
  }

  this.__tokens.push(token);

  this.__tokens_length += 1;
};

exports.TokenStream = TokenStream;
export default exports;
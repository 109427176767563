import _index from "./javascript/index";
import _index2 from "./css/index";
import _index3 from "./html/index";
var exports = {};
var js_beautify = _index;
var css_beautify = _index2;
var html_beautify = _index3;

function style_html(html_source, options, js, css) {
  js = js || js_beautify;
  css = css || css_beautify;
  return html_beautify(html_source, options, js, css);
}

style_html.defaultOptions = html_beautify.defaultOptions;
exports.js = js_beautify;
exports.css = css_beautify;
exports.html = style_html;
export default exports;
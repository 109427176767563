var exports = {};

function Token(type, text, newlines, whitespace_before) {
  this.type = type;
  this.text = text; // comments_before are
  // comments that have a new line before them
  // and may or may not have a newline after
  // this is a set of comments before

  this.comments_before = null;
  /* inline comment*/
  // this.comments_after =  new TokenStream(); // no new line before and newline after

  this.newlines = newlines || 0;
  this.whitespace_before = whitespace_before || "";
  this.parent = null;
  this.next = null;
  this.previous = null;
  this.opened = null;
  this.closed = null;
  this.directives = null;
}

exports.Token = Token;
export default exports;
import _beautifier from "./beautifier";
import _options from "./options";
var exports = {};
var Beautifier = _beautifier.Beautifier,
    Options = _options.Options;

function css_beautify(source_text, options) {
  var beautifier = new Beautifier(source_text, options);
  return beautifier.beautify();
}

exports = css_beautify;

exports.defaultOptions = function () {
  return new Options();
};

export default exports;
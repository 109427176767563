import _options from "../html/options";
import _output from "../core/output";
import _tokenizer from "../html/tokenizer";
var exports = {};
var Options = _options.Options;
var Output = _output.Output;
var Tokenizer = _tokenizer.Tokenizer;
var TOKEN = _tokenizer.TOKEN;
var lineBreak = /\r\n|[\r\n]/;
var allLineBreaks = /\r\n|[\r\n]/g;

var Printer = function (options, base_indent_string) {
  //handles input/output and some other printing functions
  this.indent_level = 0;
  this.alignment_size = 0;
  this.max_preserve_newlines = options.max_preserve_newlines;
  this.preserve_newlines = options.preserve_newlines;
  this._output = new Output(options, base_indent_string);
};

Printer.prototype.current_line_has_match = function (pattern) {
  return this._output.current_line.has_match(pattern);
};

Printer.prototype.set_space_before_token = function (value, non_breaking) {
  this._output.space_before_token = value;
  this._output.non_breaking_space = non_breaking;
};

Printer.prototype.set_wrap_point = function () {
  this._output.set_indent(this.indent_level, this.alignment_size);

  this._output.set_wrap_point();
};

Printer.prototype.add_raw_token = function (token) {
  this._output.add_raw_token(token);
};

Printer.prototype.print_preserved_newlines = function (raw_token) {
  var newlines = 0;

  if (raw_token.type !== TOKEN.TEXT && raw_token.previous.type !== TOKEN.TEXT) {
    newlines = raw_token.newlines ? 1 : 0;
  }

  if (this.preserve_newlines) {
    newlines = raw_token.newlines < this.max_preserve_newlines + 1 ? raw_token.newlines : this.max_preserve_newlines + 1;
  }

  for (var n = 0; n < newlines; n++) {
    this.print_newline(n > 0);
  }

  return newlines !== 0;
};

Printer.prototype.traverse_whitespace = function (raw_token) {
  if (raw_token.whitespace_before || raw_token.newlines) {
    if (!this.print_preserved_newlines(raw_token)) {
      this._output.space_before_token = true;
    }

    return true;
  }

  return false;
};

Printer.prototype.previous_token_wrapped = function () {
  return this._output.previous_token_wrapped;
};

Printer.prototype.print_newline = function (force) {
  this._output.add_new_line(force);
};

Printer.prototype.print_token = function (token) {
  if (token.text) {
    this._output.set_indent(this.indent_level, this.alignment_size);

    this._output.add_token(token.text);
  }
};

Printer.prototype.indent = function () {
  this.indent_level++;
};

Printer.prototype.get_full_indent = function (level) {
  level = this.indent_level + (level || 0);

  if (level < 1) {
    return "";
  }

  return this._output.get_indent_string(level);
};

var get_type_attribute = function (start_token) {
  var result = null;
  var raw_token = start_token.next; // Search attributes for a type attribute

  while (raw_token.type !== TOKEN.EOF && start_token.closed !== raw_token) {
    if (raw_token.type === TOKEN.ATTRIBUTE && raw_token.text === "type") {
      if (raw_token.next && raw_token.next.type === TOKEN.EQUALS && raw_token.next.next && raw_token.next.next.type === TOKEN.VALUE) {
        result = raw_token.next.next.text;
      }

      break;
    }

    raw_token = raw_token.next;
  }

  return result;
};

var get_custom_beautifier_name = function (tag_check, raw_token) {
  var typeAttribute = null;
  var result = null;

  if (!raw_token.closed) {
    return null;
  }

  if (tag_check === "script") {
    typeAttribute = "text/javascript";
  } else if (tag_check === "style") {
    typeAttribute = "text/css";
  }

  typeAttribute = get_type_attribute(raw_token) || typeAttribute; // For script and style tags that have a type attribute, only enable custom beautifiers for matching values
  // For those without a type attribute use default;

  if (typeAttribute.search("text/css") > -1) {
    result = "css";
  } else if (typeAttribute.search(/module|((text|application|dojo)\/(x-)?(javascript|ecmascript|jscript|livescript|(ld\+)?json|method|aspect))/) > -1) {
    result = "javascript";
  } else if (typeAttribute.search(/(text|application|dojo)\/(x-)?(html)/) > -1) {
    result = "html";
  } else if (typeAttribute.search(/test\/null/) > -1) {
    // Test only mime-type for testing the beautifier when null is passed as beautifing function
    result = "null";
  }

  return result;
};

function in_array(what, arr) {
  return arr.indexOf(what) !== -1;
}

function TagFrame(parent, parser_token, indent_level) {
  this.parent = parent || null;
  this.tag = parser_token ? parser_token.tag_name : "";
  this.indent_level = indent_level || 0;
  this.parser_token = parser_token || null;
}

function TagStack(printer) {
  this._printer = printer;
  this._current_frame = null;
}

TagStack.prototype.get_parser_token = function () {
  return this._current_frame ? this._current_frame.parser_token : null;
};

TagStack.prototype.record_tag = function (parser_token) {
  //function to record a tag and its parent in this.tags Object
  var new_frame = new TagFrame(this._current_frame, parser_token, this._printer.indent_level);
  this._current_frame = new_frame;
};

TagStack.prototype._try_pop_frame = function (frame) {
  //function to retrieve the opening tag to the corresponding closer
  var parser_token = null;

  if (frame) {
    parser_token = frame.parser_token;
    this._printer.indent_level = frame.indent_level;
    this._current_frame = frame.parent;
  }

  return parser_token;
};

TagStack.prototype._get_frame = function (tag_list, stop_list) {
  //function to retrieve the opening tag to the corresponding closer
  var frame = this._current_frame;

  while (frame) {
    //till we reach '' (the initial value);
    if (tag_list.indexOf(frame.tag) !== -1) {
      //if this is it use it
      break;
    } else if (stop_list && stop_list.indexOf(frame.tag) !== -1) {
      frame = null;
      break;
    }

    frame = frame.parent;
  }

  return frame;
};

TagStack.prototype.try_pop = function (tag, stop_list) {
  //function to retrieve the opening tag to the corresponding closer
  var frame = this._get_frame([tag], stop_list);

  return this._try_pop_frame(frame);
};

TagStack.prototype.indent_to_tag = function (tag_list) {
  var frame = this._get_frame(tag_list);

  if (frame) {
    this._printer.indent_level = frame.indent_level;
  }
};

function Beautifier(source_text, options, js_beautify, css_beautify) {
  //Wrapper function to invoke all the necessary constructors and deal with the output.
  this._source_text = source_text || "";
  options = options || {};
  this._js_beautify = js_beautify;
  this._css_beautify = css_beautify;
  this._tag_stack = null; // Allow the setting of language/file-type specific options
  // with inheritance of overall settings

  var optionHtml = new Options(options, "html");
  this._options = optionHtml;
  this._is_wrap_attributes_force = this._options.wrap_attributes.substr(0, "force".length) === "force";
  this._is_wrap_attributes_force_expand_multiline = this._options.wrap_attributes === "force-expand-multiline";
  this._is_wrap_attributes_force_aligned = this._options.wrap_attributes === "force-aligned";
  this._is_wrap_attributes_aligned_multiple = this._options.wrap_attributes === "aligned-multiple";
  this._is_wrap_attributes_preserve = this._options.wrap_attributes.substr(0, "preserve".length) === "preserve";
  this._is_wrap_attributes_preserve_aligned = this._options.wrap_attributes === "preserve-aligned";
}

Beautifier.prototype.beautify = function () {
  // if disabled, return the input unchanged.
  if (this._options.disabled) {
    return this._source_text;
  }

  var source_text = this._source_text;
  var eol = this._options.eol;

  if (this._options.eol === "auto") {
    eol = "\n";

    if (source_text && lineBreak.test(source_text)) {
      eol = source_text.match(lineBreak)[0];
    }
  } // HACK: newline parsing inconsistent. This brute force normalizes the input.


  source_text = source_text.replace(allLineBreaks, "\n");
  var baseIndentString = source_text.match(/^[\t ]*/)[0];
  var last_token = {
    text: "",
    type: ""
  };
  var last_tag_token = new TagOpenParserToken();
  var printer = new Printer(this._options, baseIndentString);
  var tokens = new Tokenizer(source_text, this._options).tokenize();
  this._tag_stack = new TagStack(printer);
  var parser_token = null;
  var raw_token = tokens.next();

  while (raw_token.type !== TOKEN.EOF) {
    if (raw_token.type === TOKEN.TAG_OPEN || raw_token.type === TOKEN.COMMENT) {
      parser_token = this._handle_tag_open(printer, raw_token, last_tag_token, last_token);
      last_tag_token = parser_token;
    } else if (raw_token.type === TOKEN.ATTRIBUTE || raw_token.type === TOKEN.EQUALS || raw_token.type === TOKEN.VALUE || raw_token.type === TOKEN.TEXT && !last_tag_token.tag_complete) {
      parser_token = this._handle_inside_tag(printer, raw_token, last_tag_token, tokens);
    } else if (raw_token.type === TOKEN.TAG_CLOSE) {
      parser_token = this._handle_tag_close(printer, raw_token, last_tag_token);
    } else if (raw_token.type === TOKEN.TEXT) {
      parser_token = this._handle_text(printer, raw_token, last_tag_token);
    } else {
      // This should never happen, but if it does. Print the raw token
      printer.add_raw_token(raw_token);
    }

    last_token = parser_token;
    raw_token = tokens.next();
  }

  var sweet_code = printer._output.get_code(eol);

  return sweet_code;
};

Beautifier.prototype._handle_tag_close = function (printer, raw_token, last_tag_token) {
  var parser_token = {
    text: raw_token.text,
    type: raw_token.type
  };
  printer.alignment_size = 0;
  last_tag_token.tag_complete = true;
  printer.set_space_before_token(raw_token.newlines || raw_token.whitespace_before !== "", true);

  if (last_tag_token.is_unformatted) {
    printer.add_raw_token(raw_token);
  } else {
    if (last_tag_token.tag_start_char === "<") {
      printer.set_space_before_token(raw_token.text[0] === "/", true); // space before />, no space before >

      if (this._is_wrap_attributes_force_expand_multiline && last_tag_token.has_wrapped_attrs) {
        printer.print_newline(false);
      }
    }

    printer.print_token(raw_token);
  }

  if (last_tag_token.indent_content && !(last_tag_token.is_unformatted || last_tag_token.is_content_unformatted)) {
    printer.indent(); // only indent once per opened tag

    last_tag_token.indent_content = false;
  }

  if (!last_tag_token.is_inline_element && !(last_tag_token.is_unformatted || last_tag_token.is_content_unformatted)) {
    printer.set_wrap_point();
  }

  return parser_token;
};

Beautifier.prototype._handle_inside_tag = function (printer, raw_token, last_tag_token, tokens) {
  var wrapped = last_tag_token.has_wrapped_attrs;
  var parser_token = {
    text: raw_token.text,
    type: raw_token.type
  };
  printer.set_space_before_token(raw_token.newlines || raw_token.whitespace_before !== "", true);

  if (last_tag_token.is_unformatted) {
    printer.add_raw_token(raw_token);
  } else if (last_tag_token.tag_start_char === "{" && raw_token.type === TOKEN.TEXT) {
    // For the insides of handlebars allow newlines or a single space between open and contents
    if (printer.print_preserved_newlines(raw_token)) {
      raw_token.newlines = 0;
      printer.add_raw_token(raw_token);
    } else {
      printer.print_token(raw_token);
    }
  } else {
    if (raw_token.type === TOKEN.ATTRIBUTE) {
      printer.set_space_before_token(true);
      last_tag_token.attr_count += 1;
    } else if (raw_token.type === TOKEN.EQUALS) {
      //no space before =
      printer.set_space_before_token(false);
    } else if (raw_token.type === TOKEN.VALUE && raw_token.previous.type === TOKEN.EQUALS) {
      //no space before value
      printer.set_space_before_token(false);
    }

    if (raw_token.type === TOKEN.ATTRIBUTE && last_tag_token.tag_start_char === "<") {
      if (this._is_wrap_attributes_preserve || this._is_wrap_attributes_preserve_aligned) {
        printer.traverse_whitespace(raw_token);
        wrapped = wrapped || raw_token.newlines !== 0;
      }

      if (this._is_wrap_attributes_force) {
        var force_attr_wrap = last_tag_token.attr_count > 1;

        if (this._is_wrap_attributes_force_expand_multiline && last_tag_token.attr_count === 1) {
          var is_only_attribute = true;
          var peek_index = 0;
          var peek_token;

          do {
            peek_token = tokens.peek(peek_index);

            if (peek_token.type === TOKEN.ATTRIBUTE) {
              is_only_attribute = false;
              break;
            }

            peek_index += 1;
          } while (peek_index < 4 && peek_token.type !== TOKEN.EOF && peek_token.type !== TOKEN.TAG_CLOSE);

          force_attr_wrap = !is_only_attribute;
        }

        if (force_attr_wrap) {
          printer.print_newline(false);
          wrapped = true;
        }
      }
    }

    printer.print_token(raw_token);
    wrapped = wrapped || printer.previous_token_wrapped();
    last_tag_token.has_wrapped_attrs = wrapped;
  }

  return parser_token;
};

Beautifier.prototype._handle_text = function (printer, raw_token, last_tag_token) {
  var parser_token = {
    text: raw_token.text,
    type: "TK_CONTENT"
  };

  if (last_tag_token.custom_beautifier_name) {
    //check if we need to format javascript
    this._print_custom_beatifier_text(printer, raw_token, last_tag_token);
  } else if (last_tag_token.is_unformatted || last_tag_token.is_content_unformatted) {
    printer.add_raw_token(raw_token);
  } else {
    printer.traverse_whitespace(raw_token);
    printer.print_token(raw_token);
  }

  return parser_token;
};

Beautifier.prototype._print_custom_beatifier_text = function (printer, raw_token, last_tag_token) {
  var local = this;

  if (raw_token.text !== "") {
    var text = raw_token.text,
        _beautifier,
        script_indent_level = 1,
        pre = "",
        post = "";

    if (last_tag_token.custom_beautifier_name === "javascript" && typeof this._js_beautify === "function") {
      _beautifier = this._js_beautify;
    } else if (last_tag_token.custom_beautifier_name === "css" && typeof this._css_beautify === "function") {
      _beautifier = this._css_beautify;
    } else if (last_tag_token.custom_beautifier_name === "html") {
      _beautifier = function (html_source, options) {
        var beautifier = new Beautifier(html_source, options, local._js_beautify, local._css_beautify);
        return beautifier.beautify();
      };
    }

    if (this._options.indent_scripts === "keep") {
      script_indent_level = 0;
    } else if (this._options.indent_scripts === "separate") {
      script_indent_level = -printer.indent_level;
    }

    var indentation = printer.get_full_indent(script_indent_level); // if there is at least one empty line at the end of this text, strip it
    // we'll be adding one back after the text but before the containing tag.

    text = text.replace(/\n[ \t]*$/, ""); // Handle the case where content is wrapped in a comment or cdata.

    if (last_tag_token.custom_beautifier_name !== "html" && text[0] === "<" && text.match(/^(<!--|<!\[CDATA\[)/)) {
      var matched = /^(<!--[^\n]*|<!\[CDATA\[)(\n?)([ \t\n]*)([\s\S]*)(-->|]]>)$/.exec(text); // if we start to wrap but don't finish, print raw

      if (!matched) {
        printer.add_raw_token(raw_token);
        return;
      }

      pre = indentation + matched[1] + "\n";
      text = matched[4];

      if (matched[5]) {
        post = indentation + matched[5];
      } // if there is at least one empty line at the end of this text, strip it
      // we'll be adding one back after the text but before the containing tag.


      text = text.replace(/\n[ \t]*$/, "");

      if (matched[2] || matched[3].indexOf("\n") !== -1) {
        // if the first line of the non-comment text has spaces
        // use that as the basis for indenting in null case.
        matched = matched[3].match(/[ \t]+$/);

        if (matched) {
          raw_token.whitespace_before = matched[0];
        }
      }
    }

    if (text) {
      if (_beautifier) {
        // call the Beautifier if avaliable
        var Child_options = function () {
          this.eol = "\n";
        };

        Child_options.prototype = this._options.raw_options;
        var child_options = new Child_options();
        text = _beautifier(indentation + text, child_options);
      } else {
        // simply indent the string otherwise
        var white = raw_token.whitespace_before;

        if (white) {
          text = text.replace(new RegExp("\n(" + white + ")?", "g"), "\n");
        }

        text = indentation + text.replace(/\n/g, "\n" + indentation);
      }
    }

    if (pre) {
      if (!text) {
        text = pre + post;
      } else {
        text = pre + text + "\n" + post;
      }
    }

    printer.print_newline(false);

    if (text) {
      raw_token.text = text;
      raw_token.whitespace_before = "";
      raw_token.newlines = 0;
      printer.add_raw_token(raw_token);
      printer.print_newline(true);
    }
  }
};

Beautifier.prototype._handle_tag_open = function (printer, raw_token, last_tag_token, last_token) {
  var parser_token = this._get_tag_open_token(raw_token);

  if ((last_tag_token.is_unformatted || last_tag_token.is_content_unformatted) && !last_tag_token.is_empty_element && raw_token.type === TOKEN.TAG_OPEN && raw_token.text.indexOf("</") === 0) {
    // End element tags for unformatted or content_unformatted elements
    // are printed raw to keep any newlines inside them exactly the same.
    printer.add_raw_token(raw_token);
    parser_token.start_tag_token = this._tag_stack.try_pop(parser_token.tag_name);
  } else {
    printer.traverse_whitespace(raw_token);

    this._set_tag_position(printer, raw_token, parser_token, last_tag_token, last_token);

    if (!parser_token.is_inline_element) {
      printer.set_wrap_point();
    }

    printer.print_token(raw_token);
  } //indent attributes an auto, forced, aligned or forced-align line-wrap


  if (this._is_wrap_attributes_force_aligned || this._is_wrap_attributes_aligned_multiple || this._is_wrap_attributes_preserve_aligned) {
    parser_token.alignment_size = raw_token.text.length + 1;
  }

  if (!parser_token.tag_complete && !parser_token.is_unformatted) {
    printer.alignment_size = parser_token.alignment_size;
  }

  return parser_token;
};

var TagOpenParserToken = function (parent, raw_token) {
  this.parent = parent || null;
  this.text = "";
  this.type = "TK_TAG_OPEN";
  this.tag_name = "";
  this.is_inline_element = false;
  this.is_unformatted = false;
  this.is_content_unformatted = false;
  this.is_empty_element = false;
  this.is_start_tag = false;
  this.is_end_tag = false;
  this.indent_content = false;
  this.multiline_content = false;
  this.custom_beautifier_name = null;
  this.start_tag_token = null;
  this.attr_count = 0;
  this.has_wrapped_attrs = false;
  this.alignment_size = 0;
  this.tag_complete = false;
  this.tag_start_char = "";
  this.tag_check = "";

  if (!raw_token) {
    this.tag_complete = true;
  } else {
    var tag_check_match;
    this.tag_start_char = raw_token.text[0];
    this.text = raw_token.text;

    if (this.tag_start_char === "<") {
      tag_check_match = raw_token.text.match(/^<([^\s>]*)/);
      this.tag_check = tag_check_match ? tag_check_match[1] : "";
    } else {
      tag_check_match = raw_token.text.match(/^{{(?:[\^]|#\*?)?([^\s}]+)/);
      this.tag_check = tag_check_match ? tag_check_match[1] : ""; // handle "{{#> myPartial}}

      if (raw_token.text === "{{#>" && this.tag_check === ">" && raw_token.next !== null) {
        this.tag_check = raw_token.next.text;
      }
    }

    this.tag_check = this.tag_check.toLowerCase();

    if (raw_token.type === TOKEN.COMMENT) {
      this.tag_complete = true;
    }

    this.is_start_tag = this.tag_check.charAt(0) !== "/";
    this.tag_name = !this.is_start_tag ? this.tag_check.substr(1) : this.tag_check;
    this.is_end_tag = !this.is_start_tag || raw_token.closed && raw_token.closed.text === "/>"; // handlebars tags that don't start with # or ^ are single_tags, and so also start and end.

    this.is_end_tag = this.is_end_tag || this.tag_start_char === "{" && (this.text.length < 3 || /[^#\^]/.test(this.text.charAt(2)));
  }
};

Beautifier.prototype._get_tag_open_token = function (raw_token) {
  //function to get a full tag and parse its type
  var parser_token = new TagOpenParserToken(this._tag_stack.get_parser_token(), raw_token);
  parser_token.alignment_size = this._options.wrap_attributes_indent_size;
  parser_token.is_end_tag = parser_token.is_end_tag || in_array(parser_token.tag_check, this._options.void_elements);
  parser_token.is_empty_element = parser_token.tag_complete || parser_token.is_start_tag && parser_token.is_end_tag;
  parser_token.is_unformatted = !parser_token.tag_complete && in_array(parser_token.tag_check, this._options.unformatted);
  parser_token.is_content_unformatted = !parser_token.is_empty_element && in_array(parser_token.tag_check, this._options.content_unformatted);
  parser_token.is_inline_element = in_array(parser_token.tag_name, this._options.inline) || parser_token.tag_start_char === "{";
  return parser_token;
};

Beautifier.prototype._set_tag_position = function (printer, raw_token, parser_token, last_tag_token, last_token) {
  if (!parser_token.is_empty_element) {
    if (parser_token.is_end_tag) {
      //this tag is a double tag so check for tag-ending
      parser_token.start_tag_token = this._tag_stack.try_pop(parser_token.tag_name); //remove it and all ancestors
    } else {
      // it's a start-tag
      // check if this tag is starting an element that has optional end element
      // and do an ending needed
      if (this._do_optional_end_element(parser_token)) {
        if (!parser_token.is_inline_element) {
          printer.print_newline(false);
        }
      }

      this._tag_stack.record_tag(parser_token); //push it on the tag stack


      if ((parser_token.tag_name === "script" || parser_token.tag_name === "style") && !(parser_token.is_unformatted || parser_token.is_content_unformatted)) {
        parser_token.custom_beautifier_name = get_custom_beautifier_name(parser_token.tag_check, raw_token);
      }
    }
  }

  if (in_array(parser_token.tag_check, this._options.extra_liners)) {
    //check if this double needs an extra line
    printer.print_newline(false);

    if (!printer._output.just_added_blankline()) {
      printer.print_newline(true);
    }
  }

  if (parser_token.is_empty_element) {
    //if this tag name is a single tag type (either in the list or has a closing /)
    // if you hit an else case, reset the indent level if you are inside an:
    // 'if', 'unless', or 'each' block.
    if (parser_token.tag_start_char === "{" && parser_token.tag_check === "else") {
      this._tag_stack.indent_to_tag(["if", "unless", "each"]);

      parser_token.indent_content = true; // Don't add a newline if opening {{#if}} tag is on the current line

      var foundIfOnCurrentLine = printer.current_line_has_match(/{{#if/);

      if (!foundIfOnCurrentLine) {
        printer.print_newline(false);
      }
    } // Don't add a newline before elements that should remain where they are.


    if (parser_token.tag_name === "!--" && last_token.type === TOKEN.TAG_CLOSE && last_tag_token.is_end_tag && parser_token.text.indexOf("\n") === -1) {//Do nothing. Leave comments on same line.
    } else {
      if (!(parser_token.is_inline_element || parser_token.is_unformatted)) {
        printer.print_newline(false);
      }

      this._calcluate_parent_multiline(printer, parser_token);
    }
  } else if (parser_token.is_end_tag) {
    //this tag is a double tag so check for tag-ending
    var do_end_expand = false; // deciding whether a block is multiline should not be this hard

    do_end_expand = parser_token.start_tag_token && parser_token.start_tag_token.multiline_content;
    do_end_expand = do_end_expand || !parser_token.is_inline_element && !(last_tag_token.is_inline_element || last_tag_token.is_unformatted) && !(last_token.type === TOKEN.TAG_CLOSE && parser_token.start_tag_token === last_tag_token) && last_token.type !== "TK_CONTENT";

    if (parser_token.is_content_unformatted || parser_token.is_unformatted) {
      do_end_expand = false;
    }

    if (do_end_expand) {
      printer.print_newline(false);
    }
  } else {
    // it's a start-tag
    parser_token.indent_content = !parser_token.custom_beautifier_name;

    if (parser_token.tag_start_char === "<") {
      if (parser_token.tag_name === "html") {
        parser_token.indent_content = this._options.indent_inner_html;
      } else if (parser_token.tag_name === "head") {
        parser_token.indent_content = this._options.indent_head_inner_html;
      } else if (parser_token.tag_name === "body") {
        parser_token.indent_content = this._options.indent_body_inner_html;
      }
    }

    if (!(parser_token.is_inline_element || parser_token.is_unformatted) && (last_token.type !== "TK_CONTENT" || parser_token.is_content_unformatted)) {
      printer.print_newline(false);
    }

    this._calcluate_parent_multiline(printer, parser_token);
  }
};

Beautifier.prototype._calcluate_parent_multiline = function (printer, parser_token) {
  if (parser_token.parent && printer._output.just_added_newline() && !((parser_token.is_inline_element || parser_token.is_unformatted) && parser_token.parent.is_inline_element)) {
    parser_token.parent.multiline_content = true;
  }
}; //To be used for <p> tag special case:


var p_closers = ["address", "article", "aside", "blockquote", "details", "div", "dl", "fieldset", "figcaption", "figure", "footer", "form", "h1", "h2", "h3", "h4", "h5", "h6", "header", "hr", "main", "nav", "ol", "p", "pre", "section", "table", "ul"];
var p_parent_excludes = ["a", "audio", "del", "ins", "map", "noscript", "video"];

Beautifier.prototype._do_optional_end_element = function (parser_token) {
  var result = null; // NOTE: cases of "if there is no more content in the parent element"
  // are handled automatically by the beautifier.
  // It assumes parent or ancestor close tag closes all children.
  // https://www.w3.org/TR/html5/syntax.html#optional-tags

  if (parser_token.is_empty_element || !parser_token.is_start_tag || !parser_token.parent) {
    return;
  }

  if (parser_token.tag_name === "body") {
    // A head element’s end tag may be omitted if the head element is not immediately followed by a space character or a comment.
    result = result || this._tag_stack.try_pop("head"); //} else if (parser_token.tag_name === 'body') {
    // DONE: A body element’s end tag may be omitted if the body element is not immediately followed by a comment.
  } else if (parser_token.tag_name === "li") {
    // An li element’s end tag may be omitted if the li element is immediately followed by another li element or if there is no more content in the parent element.
    result = result || this._tag_stack.try_pop("li", ["ol", "ul"]);
  } else if (parser_token.tag_name === "dd" || parser_token.tag_name === "dt") {
    // A dd element’s end tag may be omitted if the dd element is immediately followed by another dd element or a dt element, or if there is no more content in the parent element.
    // A dt element’s end tag may be omitted if the dt element is immediately followed by another dt element or a dd element.
    result = result || this._tag_stack.try_pop("dt", ["dl"]);
    result = result || this._tag_stack.try_pop("dd", ["dl"]);
  } else if (parser_token.parent.tag_name === "p" && p_closers.indexOf(parser_token.tag_name) !== -1) {
    // IMPORTANT: this else-if works because p_closers has no overlap with any other element we look for in this method
    // check for the parent element is an HTML element that is not an <a>, <audio>, <del>, <ins>, <map>, <noscript>, or <video> element,  or an autonomous custom element.
    // To do this right, this needs to be coded as an inclusion of the inverse of the exclusion above.
    // But to start with (if we ignore "autonomous custom elements") the exclusion would be fine.
    var p_parent = parser_token.parent.parent;

    if (!p_parent || p_parent_excludes.indexOf(p_parent.tag_name) === -1) {
      result = result || this._tag_stack.try_pop("p");
    }
  } else if (parser_token.tag_name === "rp" || parser_token.tag_name === "rt") {
    // An rt element’s end tag may be omitted if the rt element is immediately followed by an rt or rp element, or if there is no more content in the parent element.
    // An rp element’s end tag may be omitted if the rp element is immediately followed by an rt or rp element, or if there is no more content in the parent element.
    result = result || this._tag_stack.try_pop("rt", ["ruby", "rtc"]);
    result = result || this._tag_stack.try_pop("rp", ["ruby", "rtc"]);
  } else if (parser_token.tag_name === "optgroup") {
    // An optgroup element’s end tag may be omitted if the optgroup element is immediately followed by another optgroup element, or if there is no more content in the parent element.
    // An option element’s end tag may be omitted if the option element is immediately followed by another option element, or if it is immediately followed by an optgroup element, or if there is no more content in the parent element.
    result = result || this._tag_stack.try_pop("optgroup", ["select"]); //result = result || this._tag_stack.try_pop('option', ['select']);
  } else if (parser_token.tag_name === "option") {
    // An option element’s end tag may be omitted if the option element is immediately followed by another option element, or if it is immediately followed by an optgroup element, or if there is no more content in the parent element.
    result = result || this._tag_stack.try_pop("option", ["select", "datalist", "optgroup"]);
  } else if (parser_token.tag_name === "colgroup") {
    // DONE: A colgroup element’s end tag may be omitted if the colgroup element is not immediately followed by a space character or a comment.
    // A caption element's end tag may be ommitted if a colgroup, thead, tfoot, tbody, or tr element is started.
    result = result || this._tag_stack.try_pop("caption", ["table"]);
  } else if (parser_token.tag_name === "thead") {
    // A colgroup element's end tag may be ommitted if a thead, tfoot, tbody, or tr element is started.
    // A caption element's end tag may be ommitted if a colgroup, thead, tfoot, tbody, or tr element is started.
    result = result || this._tag_stack.try_pop("caption", ["table"]);
    result = result || this._tag_stack.try_pop("colgroup", ["table"]); //} else if (parser_token.tag_name === 'caption') {
    // DONE: A caption element’s end tag may be omitted if the caption element is not immediately followed by a space character or a comment.
  } else if (parser_token.tag_name === "tbody" || parser_token.tag_name === "tfoot") {
    // A thead element’s end tag may be omitted if the thead element is immediately followed by a tbody or tfoot element.
    // A tbody element’s end tag may be omitted if the tbody element is immediately followed by a tbody or tfoot element, or if there is no more content in the parent element.
    // A colgroup element's end tag may be ommitted if a thead, tfoot, tbody, or tr element is started.
    // A caption element's end tag may be ommitted if a colgroup, thead, tfoot, tbody, or tr element is started.
    result = result || this._tag_stack.try_pop("caption", ["table"]);
    result = result || this._tag_stack.try_pop("colgroup", ["table"]);
    result = result || this._tag_stack.try_pop("thead", ["table"]);
    result = result || this._tag_stack.try_pop("tbody", ["table"]); //} else if (parser_token.tag_name === 'tfoot') {
    // DONE: A tfoot element’s end tag may be omitted if there is no more content in the parent element.
  } else if (parser_token.tag_name === "tr") {
    // A tr element’s end tag may be omitted if the tr element is immediately followed by another tr element, or if there is no more content in the parent element.
    // A colgroup element's end tag may be ommitted if a thead, tfoot, tbody, or tr element is started.
    // A caption element's end tag may be ommitted if a colgroup, thead, tfoot, tbody, or tr element is started.
    result = result || this._tag_stack.try_pop("caption", ["table"]);
    result = result || this._tag_stack.try_pop("colgroup", ["table"]);
    result = result || this._tag_stack.try_pop("tr", ["table", "thead", "tbody", "tfoot"]);
  } else if (parser_token.tag_name === "th" || parser_token.tag_name === "td") {
    // A td element’s end tag may be omitted if the td element is immediately followed by a td or th element, or if there is no more content in the parent element.
    // A th element’s end tag may be omitted if the th element is immediately followed by a td or th element, or if there is no more content in the parent element.
    result = result || this._tag_stack.try_pop("td", ["table", "thead", "tbody", "tfoot", "tr"]);
    result = result || this._tag_stack.try_pop("th", ["table", "thead", "tbody", "tfoot", "tr"]);
  } // Start element omission not handled currently
  // A head element’s start tag may be omitted if the element is empty, or if the first thing inside the head element is an element.
  // A tbody element’s start tag may be omitted if the first thing inside the tbody element is a tr element, and if the element is not immediately preceded by a tbody, thead, or tfoot element whose end tag has been omitted. (It can’t be omitted if the element is empty.)
  // A colgroup element’s start tag may be omitted if the first thing inside the colgroup element is a col element, and if the element is not immediately preceded by another colgroup element whose end tag has been omitted. (It can’t be omitted if the element is empty.)
  // Fix up the parent of the parser token


  parser_token.parent = this._tag_stack.get_parser_token();
  return result;
};

exports.Beautifier = Beautifier;
export default exports;